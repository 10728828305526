import theme from "../theme"

export default function progressBar(num, total) {
  if (num >= total) {
    return (
      <>
        <span
          style={{
            fontFamily: "Sofia Pro",
            color: theme.palette.rc_yellow.main,
            fontWeight: "bold",
          }}
        >
          *COMPLETE*
        </span>
        <span> </span>
        <span>
          ({num}/{total})
        </span>
      </>
    )
  } else {
    return (
      <>
        <span
          sx={{
            display: "inline-block",
          }}
        >
          <span>IN PROGRESS</span>
          <span> </span>
          <span style={{ letterSpacing: -3 }}>
            <span style={{ color: theme.palette.rc_yellow.main }}>
              {Array(num).fill(
                "█"
                // "▋"
              )}
            </span>
            <span style={{ color: "#666666" }}>
              {Array(total - num).fill(
                "█"
                //"▋"
              )}
            </span>
          </span>
        </span>
        <span> </span>
        <span>
          ({num}/{total})
        </span>
      </>
    )
  }
}

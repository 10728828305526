import {useEffect,useState} from 'react'

import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import RocketLaunchIcon from "@mui/icons-material/RocketLaunch"

import coin from "../Images/RC_Acedamy_seal_small.png"
import header_bg from "../Images/resume_header_background.png"

import theme from "../theme"

import progressBar from "./progressBar"

export default function HeaderSection({userData,otherCerts}) {

  const [num_series_a, setNumSeriesA] = useState(0)
  const [num_series_b, setNumSeriesB] = useState(0)

  useEffect(()=>{
    const sa = userData.certifications.seriesA
    const sb = userData.certifications.seriesB
    var num_sa = 0 
    var num_sb = 0
    for (const a in sa){
      if(sa[a].status == "pass"){num_sa++}
    }
    for (const b in sb){
      if(sb[b].status == "pass"){
        if(num_sa < 24){
          num_sa++
        } else {
          num_sb++
        }
      }
    }
    setNumSeriesA(num_sa)
    setNumSeriesB(num_sb)
  },[])

  return (
    <Box
      sx={{
        backgroundImage: "url(" + header_bg + ")",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src={coin}
        className="rotate-in-2-cw"
        style={{
          height: 170,
          marginTop: 62,
        }}
      />
      <Box
        sx={{
          backgroundColor: "#212123",
          width: "100%",
          height: 70,
          pt: 1,
          mt: 3,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={userData.name.length < 20 ? 45 : 33}
          textAlign="center"
          color={theme.palette.rc_yellow.main}
          sx={{
            width: "100%",
            fontFamily: "Sofia Pro",
            letterSpacing: "0.15em",
          }}
        >
          - {userData.name.toUpperCase()} -
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#666666",
          width: "100%",
          pt: "7px",
          pb: "3px",
        }}
      >
        <Typography
          fontSize={15}
          textAlign="center"
          color="#fff"
          sx={{
            fontFamily: "Aleo",
            letterSpacing: "0.3em",
          }}
        >
          CERTIFICATION PROGRESS
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#212123",
          width: "100%",
          pt: "3px",
          pb: "3px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={12}
          textAlign="right"
          color="#fff"
          sx={{
            width: "30%",
            fontFamily: "Aleo",
            letterSpacing: "0.3em",
          }}
        >
          SERIES A:
        </Typography>
        <Typography
          fontSize={12}
          textAlign="left"
          color="#fff"
          flexGrow={1}
          sx={{
            fontFamily: "Aleo",
            letterSpacing: "0.3em",
            pl: 1,
          }}
        >
          {progressBar(num_series_a, 24)}
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#212123",
          width: "100%",
          pt: "3px",
          pb: "3px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={12}
          textAlign="right"
          color="#fff"
          sx={{
            width: "30%",
            fontFamily: "Aleo",
            letterSpacing: "0.3em",
          }}
        >
          SERIES B:
        </Typography>
        <Typography
          fontSize={12}
          textAlign="left"
          color="#999"
          flexGrow={1}
          sx={{
            fontFamily: "Aleo",
            letterSpacing: "0.3em",
            pl: 1,
          }}
        >
          {num_series_a>=24?progressBar(num_series_b,12):"UNLOCKS AFTER SERIES A COMPLETION"}
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#212123",
          width: "100%",
          pt: "3px",
          pb: "3px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={12}
          textAlign="right"
          color="#fff"
          sx={{
            width: "30%",
            fontFamily: "Aleo",
            letterSpacing: "0.3em",
          }}
        >
          UNIVERSITY:
        </Typography>
        <Typography
          fontSize={12}
          textAlign="left"
          color="#999"
          flexGrow={1}
          sx={{
            fontFamily: "Aleo",
            letterSpacing: "0.3em",
            pl: 1,
          }}
        >
          {userData.certifications.seriesA.length>=24
            ?progressBar(
              userData.certifications.university.length,
              userData.certifications.university.length + otherCerts.university.length
            )
            :"UNLOCKS AFTER SERIES A COMPLETION"
          }
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Typography
          color="#fff"
          fontSize={18}
          textAlign="center"
          sx={{
            width: "100%",
            mt: 3,
            mb: 0,
            fontFamily: "Sofia Pro",
            letterSpacing: "0.4em",
          }}
        >
          TEAM {userData.team_name.toUpperCase()}
        </Typography>
        <Typography
          color="#fff"
          fontSize={25}
          textAlign="center"
          sx={{
            width: "100%",
            fontFamily: "Sofia Pro",
            letterSpacing: "0.1em",
          }}
        >
          <RocketLaunchIcon
            sx={{
              fill: theme.palette.rc_yellow.main,
              mt: "5px",
              mr: "5px",
            }}
          />{" "}
          TOTAL ROCKET FUEL
        </Typography>
        <Typography
          color={theme.palette.rc_yellow.main}
          fontSize={25}
          textAlign="center"
          sx={{
            width: "100%",
            fontFamily: "Sofia Pro",
            letterSpacing: "0.1em",
            mt: 0.5,
            mb: 5,
          }}
        >
          {userData.total_rf.toLocaleString()} RF
        </Typography>
      </Box>
    </Box>
  )
}

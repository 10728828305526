export function testHoldDates(start,end){
  var inside = false

  const current_date = new Date()
  let start_date = new Date(new Date(start).setDate(1))
  let end_date = new Date(new Date(end).setDate(1))
  end_date = new Date(1900+end_date.getYear(),end_date.getMonth()+1,0)
  end_date = end_date

  inside = (current_date.getTime() > start_date.getTime() && current_date.getTime() < end_date.getTime())
  return(inside)
}

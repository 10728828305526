import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { supabase } from "./supabaseClient"

import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import LoadingButton from "@mui/lab/LoadingButton"

import coin from "./Images/RC_Acedamy_seal_small.png"

import theme from "./theme"

export function Landing(props) {
  const [memberID, setMemberID] = useState("")
  const [error, setError] = useState(false)
  const [somethingWentWrong, setSomethingWentWrong] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [found, setFound] = useState(false)
  const [successText, setSuccessText] = useState('member found')
  const [loading, setLoading] = useState(false)
  const [loadNext, setLoadNext] = useState(false)
  const history = useHistory()

  function handleMemberIDInput(e) {
    var input_text = e.target.value
    let re = /\d\d\d\d/
    setNotFound(false)
    setFound(false)
    if (input_text.length >= 4) {
      setError(re.test(input_text) == false)
    } else {
      setError(false)
    }
    setMemberID(e.target.value.substring(0, 4))
  }

  async function handleSubmit() {
    if (loading == false && error == false && memberID.length == 4) {
      setLoading(true)
      setError(false)
      setSomethingWentWrong(false)
      setNotFound(false)
      setFound(false)
      setSomethingWentWrong(false)
      await delay(1500)
      const {data,error} = await supabase
        .from('members')
        .select()
        .match({'member_id':memberID})
      if(error){
        console.log(error)
        setSomethingWentWrong(true)
        setLoading(false)
        return
      }
      if(data.length == 0){
        setLoading(false)
        setNotFound(true)
        return
      }
      setFound(true)
      setLoading(false)
      await delay(500)
      setSuccessText('loading resume...')
      await delay(500)
      setLoadNext(true)
      await delay(500)
      history.push('/resume/'+data[0].uuid)
    }
  }

  return (
    <>
      <Box
        className={loadNext && "slide-out-top"}
        sx={{
          width: "100%",
          maxWidth: 800,
          backgroundColor: "#000000a3",
          py: 4,
          px: 2,
          mt: "5vw",
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
          <img
            className="rotate-in-2-cw"
            src={coin}
            style={{
              height: 200,
            }}
          />
        </Box>
        <Typography
          variant="h3"
          textAlign="center"
          sx={{
            color: "#fff",
            fontFamily: "Sofia Pro",
          }}
        >
          Rocket Club Member Portal
        </Typography>
        <Typography
          variant="h6"
          textAlign="center"
          sx={{
            mt: 3,
            color: "#fff",
            fontFamily: "Sofia Pro",
          }}
        >
          Enter your member ID to log in
        </Typography>
      </Box>
      <Box
        className={loadNext && "slide-out-top"}
        sx={{
          width: "100%",
          maxWidth: 800,
          backgroundColor: "#000000a3",
          pt: 3,
          pb: 5,
          mt: 3,
          mb: 50,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          textAlign="center"
          sx={{
            mt: 1,
            mb: 2,
            color: "#fff",
            fontFamily: "Sofia Pro",
            transition: "ease-in-out 0.5s",
          }}
        >
          MEMBER ID
        </Typography>
        <Box
          sx={{
            backgroundColor: "#ffde5917",
            backgroundOpacity: 0.1,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <TextField
            color="rc_yellow"
            variant="standard"
            sx={{
              width: 200,
            }}
            value={memberID}
            onChange={(e) => {
              handleMemberIDInput(e)
            }}
            error={error == true || notFound == true}
            inputProps={{
              autoComplete: "off",
              "data-lpignore": "true",
              "data-form-type": "text",
              style: {
                color: "#fff",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                textAlign: "center",
                paddingTop: "6px",
                paddingBottom: "6px",
              },
            }}
          />
        </Box>
        <Typography
          color={somethingWentWrong ? "error" : "transparent"}
          sx={{
            fontFamily: "Sofia Pro",
            height: "0px",
            transition: "ease-in 0.1s",
          }}
        >
          {somethingWentWrong ? "Something went wrong..." : "invalid member id"}
        </Typography>
        <Typography
          color={loading ? "#ffefad" : "transparent"}
          sx={{
            fontFamily: "Sofia Pro",
            height: "0px",
            transition: "all 0.25s",
          }}
        >
          loading...
        </Typography>
        <Typography
          color={notFound ? "error" : "transparent"}
          sx={{
            fontFamily: "Sofia Pro",
            height: "0px",
            transition: "all 0.25s",
          }}
        >
          Member Not Found.
        </Typography>
        <Typography
          color={found ? "#6bff6b" : "transparent"}
          sx={{
            fontFamily: "Sofia Pro",
            height: "0px",
            transition: "all 0.25s",
          }}
        >
          {successText}
        </Typography>
        <Box
          sx={{
            mt: 3,
            borderRadius: "4px",
            transition: "all 0.5s",
            backgroundColor: error ? "#ffadad" : "#ffefad",
          }}
        >
          <LoadingButton
            variant="contained"
            loading={loading}
            color="rc_yellow"
            size="large"
            disabled={error == true || found == true}
            onClick={handleSubmit}
            sx={{
              color: "black",
              width: 150,
            }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </>
  )
}

const delay = ms => new Promise(res => setTimeout(res, ms));

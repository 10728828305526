import React, { useState, useEffect, useRef, createRef } from "react"
import { supabase } from "./supabaseClient"

import { useHistory, useParams } from "react-router-dom"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

import theme from "./theme"

// resume components
import HeaderSection from "./ResumeComponents/HeaderSection"
import LeftSection from "./ResumeComponents/LeftSection"
import RightSection from "./ResumeComponents/RightSection"

import { testHoldDates } from "./testHoldDates"

export function Resume(props) {
  let { member_uid } = useParams()
  let history = useHistory()

  const resume_doc = createRef()

  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [otherCerts, setOtherCerts] = useState(null)
  const [max_other_certs, setMax_other_certs] = useState(null)
  const [current_monthly_cert, setCurrentMonthlyCert] = useState(null)

  const [ready, setReady] = useState(false)

  useEffect(() => {
    getUserData()
    getCurrentCoreCert()
  }, [])

  async function getCurrentCoreCert() {
    const { data, error } = await supabase
      .from('preferences')
      .select()
      .match({ 'name': 'current_monthly_cert' })
    setCurrentMonthlyCert(data[0].value.current)
  }

  async function getUserData() {
    const { data, error } = await supabase
      .rpc('resume_get_member', { 'member_uuid': member_uid })
    // handle error and not found
    if (error) {
      console.log(error)
    }
    if (data == null || data.length == 0) {
      console.log('member not found.')
      history.push('/')
    }
    setUserData(data[0])
  }

  useEffect(() => {
    if (
      userData != null
      && ready == false
      && userData.certifications == null
      && current_monthly_cert != null
    ) {
      getMemberCerts()
    }
  }, [userData, current_monthly_cert])

  async function getMemberCerts() {

    var duplicates = []
    // get the userData as a temp object so we can add to it
    var temp_data = Object.assign(Object.create({}), userData)
    temp_data.member_certifications = []
    temp_data.certifications = {}
    temp_data.certifications.core = []
    temp_data.certifications.seriesA = []
    temp_data.certifications.seriesB = []
    temp_data.certifications.technology = []
    temp_data.certifications.university = []
    temp_data.certifications.entre = []
    temp_data.certifications.stem = []
    temp_data.certifications.community = []
    temp_data.certifications.eq_teamwork = []
    temp_data.certifications.eq_creativity = []
    temp_data.certifications.eq_critical_thinking = []
    temp_data.certifications.eq_communication = []
    temp_data.certifications.eq_confidence = []
    temp_data.eq_badges = []
    temp_data.otherCerts = []
    temp_data.awards = []
    // fetch user certifications from the database
    const { data, error } = await supabase
      .rpc('resume_get_member_certifications', { 'member_uuid_q': member_uid })
    //bandaid fix - flip the order of the cert data to process most recent records first
    //need to identify why duplicate cert records even exist
    const sortedData = data.reverse();
    for (const c of sortedData) {
      if (duplicates.includes(c.id)) {
        console.log('duplicates includes cert id' + c.id + '- continue');
        continue;
      }
      console.log('duplicates push cert id' + c.id);
      duplicates.push(c.id)
      console.log('temp_data.member_certifications push' + c.id);
      temp_data.member_certifications.push(c.id)
      if (c.active) {
        if (c.category == "science_and_tech") {
          temp_data.certifications.stem.push(c)
          temp_data.certifications.core.push(c)
        }
        if (c.category == "entre" || c.category == "science_and_tech") {
          if (temp_data.certifications.seriesA.length < 24) {
            temp_data.certifications.seriesA.push(c)
          } else {
            temp_data.certifications.seriesB.push(c)
          }
        }
        if (c.category == "entre") {
          temp_data.certifications.entre.push(c)
          temp_data.certifications.core.push(c)
        }
        if (c.category == "award") { temp_data.awards.push(c) }
        if (c.category == "university") { temp_data.certifications.university.push(c) }
        if (c.category == "technology") { temp_data.certifications.technology.push(c) }

        // eq_badge directory will still be the source of truth when rendering the resume;
        if (c.series == "eq_cert") {
          switch (c.category) {
            case "Communication":
              temp_data.certifications.eq_communication.push(c)
            case "Confidence":
              temp_data.certifications.eq_confidence.push(c)
            case "Creativity":
              temp_data.certifications.eq_creativity.push(c)
            case "Critical_Thinking":
              temp_data.certifications.eq_critical_thinking.push(c)
            case "Teamwork":
              temp_data.certifications.eq_teamwork.push(c)
          }
        }
        //despite counting number of completed eq_activities a member has for each eq category, still check for eq_badge to account for manual overrides;
        if (c.category == "eq_badge") { temp_data.eq_badges.push(c) }
        if (c.category == "community") { temp_data.certifications.community.push(c) }
      }
    }

    //handle EQ cert type counting + badge handling here;
    // if count of eq_certs in each eq_type array >= 4, add id of associated eq_badge to the eq_badges array so it gets displayed on the resume (if it wasn't already added from previous step)
    const communication_badge_id = 280
    const confidence_badge_id = 281
    const creativity_badge_id = 282
    const critical_thinking_badge_id = 283
    const teamwork_badge_id = 284
    
    const eq_activity_count_threshold = 4

    if (temp_data.certifications.eq_communication.length >= eq_activity_count_threshold &&
      !temp_data.eq_badges.includes(communication_badge_id)) {
      temp_data.eq_badges.push(communication_badge_id)
    }
    if (temp_data.certifications.eq_confidence.length >= eq_activity_count_threshold &&
      !temp_data.eq_badges.includes(confidence_badge_id)) {
      temp_data.eq_badges.push(confidence_badge_id)
    }
    if (temp_data.certifications.eq_creativity.length >= eq_activity_count_threshold &&
      !temp_data.eq_badges.includes(creativity_badge_id)) {
      temp_data.eq_badges.push(creativity_badge_id)
    }
    if (temp_data.certifications.eq_critical_thinking.length >= eq_activity_count_threshold &&
      !temp_data.eq_badges.includes(critical_thinking_badge_id)) {
      temp_data.eq_badges.push(critical_thinking_badge_id)
    }
    if (temp_data.certifications.eq_teamwork.length >= eq_activity_count_threshold &&
      !temp_data.eq_badges.includes(teamwork_badge_id)) {
      temp_data.eq_badges.push(teamwork_badge_id)
    }
    //sort badge data to ensure consistent ordered appearance on actual resume
    temp_data.eq_badges.sort()

    setUserData(temp_data)
  }



  async function getOtherCerts() {
    var num_entre = userData.certifications.entre.length
    var num_stem = userData.certifications.stem.length
    const other_certs = {
      entre: [],
      stem: [],
      technology: [],
      university: [],
      currentCoreCert: null
    }
    const { data, error } = await supabase
      .from('certifications')
      .select()
      .match({ 'active': true })
    data.forEach((c, i) => {
      if (!userData.member_certifications.includes(c.id)) {
        if (c.id == current_monthly_cert) {
          if (['active', 'certified active'].includes(userData.account_status.status)) {
            other_certs.currentCoreCert = c
          }
        }
      }
    })
    if (other_certs.currentCoreCert == null) {
      other_certs.currentCoreCert = { id: -1, category: 'none', name: '' }
    }
    data.forEach((c, i) => {
      if (!userData.member_certifications.includes(c.id)) {

        if (c.category == 'entre' && num_entre < 12 && c.id != other_certs.currentCoreCert.id) {
          if (other_certs.currentCoreCert.category == 'entre') {
            if (num_entre == 10) {
              num_entre += 2
            } else { num_entre++ }
          } else {
            num_entre++
          }
          other_certs.entre.push(c)
        }

        if (c.category == 'science_and_tech' && num_stem < 12 && c.id != other_certs.currentCoreCert.id) {
          if (other_certs.currentCoreCert.category == 'science_and_tech') {
            if (num_stem == 10) {
              num_stem += 2
            } else { num_stem++ }
          } else {
            num_stem++
          }
          other_certs.stem.push(c)
        }

        if (c.category == "technology") { other_certs.technology.push(c) }
        if (c.category == "university") { other_certs.university.push(c) }

      }
      if (data.length == i + 1) {
        setOtherCerts(other_certs)
      }
    })
  }

  async function getAccountHold() {
    var temp_data = Object.assign(Object.create({}), userData)
    temp_data.on_hold = false
    const { data, error } = await supabase
      .from("account_holds")
      .select()
      .match({ 'member_uuid': member_uid })
    const current_date = new Date()
    if (data) {
      data.forEach((p) => {
        if (testHoldDates(p.start, p.end)) {
          temp_data.on_hold = true
        }
      })
    }
    setUserData(temp_data)
    setReady(true)
  }

  useEffect(() => {
    if (
      userData != null
      && userData.certifications != null
      && otherCerts == null
    ) {
      getOtherCerts()
    }
  }, [userData])

  useEffect(() => {
    if (
      userData != null
      && userData.on_hold == null
      && otherCerts != null
    ) {
      getAccountHold()
    }
  }, [otherCerts])

  if (!ready) { return (<></>) }

  function handleDownload() {
  }

  return (
    <Box
      className="fade-in"
      sx={{
        width: 840,
        backgroundColor: "#373741",
        mt: "5vw",
        userSelect: "none",
        mb: 20,
      }}
    >
      <HeaderSection userData={userData} otherCerts={otherCerts} />
      <Box display="flex" flexDirection="row" ref={resume_doc}>
        <LeftSection userData={userData} />
        <RightSection userData={userData} otherCerts={otherCerts} />
      </Box>
    </Box>
  )
}

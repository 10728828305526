import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import profile_placeholder from "../Images/Academy Seal White 500x500.png"

import theme from "../theme"

export default function LeftSection({userData}) {
  function LeftSideSection(props) {
    return (
      <Box
        sx={{
          mx: "15px",
          mt: "15px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Sofia Pro",
            borderBottom: "4px solid #ffd940",
            mb: "5px",
          }}
        >
          {props.title}
        </Typography>
        {props.children}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        width: "35%",
        backgroundColor: "#212123",
        borderRight: "8px solid #373741",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        src={profile_placeholder}
        style={{
          width: "100%",
        }}
      />
      <LeftSideSection title="CONTACT INFORMATION">
        <Typography
          sx={{
            fontFamily: "Sofia Pro",
          }}
        >
          <b>Name:</b> {userData.name}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Sofia Pro",
          }}
        >
          <b>Email:</b> admin@rocketclub.com
        </Typography>
        <Typography
          sx={{
            fontFamily: "Sofia Pro",
          }}
        >
          <b>Website:</b> www.rocketclub.com
        </Typography>
      </LeftSideSection>
      <Box
        sx={{
          minHeight: "150px",
        }}
      >
        <LeftSideSection title="T-SHIRT COMPANY">
          <Typography style={{whiteSpace:'break-spaces'}}>
            {userData.t_shirt_company ? userData.t_shirt_company : "COMING SOON"}
          </Typography>
        </LeftSideSection>
      </Box>
      <Box
        sx={{
          minHeight: "150px",
        }}
      >
        <LeftSideSection title="AWARDS">
          <ul style={{ paddingLeft: "20px" }}>
            {userData.awards.map((a) => {
              return (
                <li key={a.name}>
                  <Typography
                    fontSize={17}
                    sx={{
                      fontFamily: "Sofia Pro",
                      mb: 1,
                      ml: 1,
                      p: 0,
                    }}
                  >
                    {a.name}
                  </Typography>
                </li>
              )
            })}
            {userData.certifications.technology.map((a) => {
              return (
                <li key={a.name}>
                  <Typography
                    fontSize={17}
                    sx={{
                      fontFamily: "Sofia Pro",
                      mb: 1,
                      ml: 1,
                      p: 0,
                    }}
                  >
                    {a.name}
                  </Typography>
                </li>
              )
            })}
            {userData.certifications.community.map((a) => {
              return (
                <li key={a.name}>
                  <Typography
                    fontSize={17}
                    sx={{
                      fontFamily: "Sofia Pro",
                      mb: 1,
                      ml: 1,
                      p: 0,
                    }}
                  >
                    {a.name}
                  </Typography>
                </li>
              )
            })}
          </ul>
        </LeftSideSection>
      </Box>
      <Box flexGrow={1} />
      <Box
        sx={{
          height: "250px",
        }}
      >
        <LeftSideSection title="EQ BADGES">
          <Box sx={{display:'flex',flexWrap:'wrap'}}>
            {userData.eq_badges.map((b,i)=>(
              <EQBadge cert_id={b.id} key={i}/>
            ))}
          </Box>
        </LeftSideSection>
      </Box>
    </Box>
  )
}

function EQBadge({cert_id}){
  return(
      <img 
        src={`https://kmadxvwuntpllwupultb.supabase.in/storage/v1/object/public/public-images/eq_${cert_id}.png`}
        style={{width:'40%',minWidth:'40%'}}
      />
  )
}

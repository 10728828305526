import { red } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#1565c0",
    },
    secondary: {
      main: "#f57c00",
    },
    success: {
      main: "#22aa22",
    },
    red: {
      main: "#55aa55",
    },
    rc_yellow: {
      main: "#ffd940",
    },
  },
})

export default theme

import React, { useState, useEffect } from "react"

//react router
import {
  BrowserRouter as Router,
  Link as RouterLink,
  Switch,
  Route,
  Redirect,
  Outlet,
} from "react-router-dom"

//mui
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"

import theme from "./theme"

//PAGES
import { Landing } from "./Landing"
import { Resume } from "./Resume"

import page_bg from "./Images/Member Portal black bakground.png"

import { Footer } from "./Footer"

export default function App(props) {
  const [MemberID, setMemberId] = useState(null)

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            minHeight: "100vh",
            backgroundImage: 'url("' + page_bg + '")',
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundPositionX: "left",
            color: "#fff",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            flexGrow={1}
          >
            <Switch>
              <Route exact path="/">
                <Landing />
              </Route>
              <Route exact path="/resume">
                <Redirect to="/" />
              </Route>
              <Route exact path="/resume/:member_uid">
                <Resume />
              </Route>
            </Switch>
          </Box>
        </Box>
      </ThemeProvider>
    </Router>
  )
}

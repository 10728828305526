import React, { useState, useEffect } from "react"

import { useHistory, useParams } from "react-router-dom"

import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import RocketLaunchIcon from "@mui/icons-material/RocketLaunch"
import LockIcon from "@mui/icons-material/Lock"

import theme from "../theme"

const did_not_pass_color = "#e1a8a8";

export default function RightSection({userData,otherCerts}) {
  const entre_extra = (otherCerts.currentCoreCert.category == 'entre'?2:1)
  return (
    <Box
      flexGrow={2}
      sx={{
        borderLeft: "4px solid #fff",
        px: "1px",
      }}
    >
      <Box
        backgroundColor={theme.palette.rc_yellow.main}
        sx={{
          pt: "6px",
          pb: "3px",
        }}
      >
        <Typography
          color="#212123"
          textAlign="center"
          fontSize="20px"
          sx={{
            fontFamily: "Sofia Pro",
            p: 0,
            m: 0,
          }}
        >
          ROCKET CLUB ACADEMY CORE CERTIFICATIONS
        </Typography>
      </Box>
      <Box
        backgroundColor="#212123"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: "6px",
          pb: "3px",
        }}
      >
        <Typography
          textAlign="center"
          fontSize="20px"
          sx={{
            fontFamily: "Sofia Pro",
            p: 0,
            m: 0,
            mb: "5px",
            height: "23px",
            width: "192px",
            borderBottom: "2px solid white",
          }}
        >
          ENTREPRENEURSHIP
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mt: "10px",
          mx: "10px",
          height: "18px",
          borderBottom: "1px solid white",
        }}
      >
        <Typography
          flexGrow={1}
          sx={{
            mr: "5px",
            fontFamily: "Sofia Pro",
          }}
        >
          JOURNEY NAME
        </Typography>
        <Typography
          sx={{
            mr: "5px",
            fontFamily: "Sofia Pro",
          }}
        >
          STATUS
        </Typography>
      </Box>
      {userData.certifications.entre.map((c,i) => {
        return (
          <Box
            key={c.name}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              pt: "4px",
            }}
          >
            <Typography
              sx={{
                width: 30,
                textAlign: "right",
                mr: "5px",
                fontFamily: "Sofia Pro",
                color:c.status == "pass" ? "#fff" : did_not_pass_color,
              }}
            >
              {i + 1 + "."}
            </Typography>
            <Typography
              flexGrow={1}
              sx={{
                fontFamily: "Sofia Pro",
                color:c.status == "pass" ? "#fff" : did_not_pass_color,
              }}
            >
              {c.name}
            </Typography>
            <Typography
              sx={{
                mr: "10px",
                fontFamily: "Sofia Pro",
                color:c.status == "pass" ? "#fff" : did_not_pass_color,
              }}
            >
              {c.status == "pass" ? "CERTIFIED" : "DID NOT PASS"}
            </Typography>
          </Box>
        )
      })}
      {otherCerts.currentCoreCert 
        && otherCerts.currentCoreCert.category == "entre" 
        && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            pt: "4px",
            color: theme.palette.rc_yellow.main,
          }}
        >
          <Typography
            sx={{
              width: 30,
              textAlign: "right",
              mr: "5px",
              fontFamily: "Sofia Pro",
            }}
          >
            {userData.certifications.entre.length + 1 + "."}
          </Typography>
          <Typography
            flexGrow={1}
            sx={{
              fontFamily: "Sofia Pro",
            }}
          >
            {otherCerts.currentCoreCert.name}
          </Typography>
          <Typography
            sx={{
              mr: "10px",
              fontFamily: "Sofia Pro",
            }}
          >
            IN PROGRESS
          </Typography>
        </Box>
      )}
      {otherCerts.entre.map((c,i) => {
        return (
          <Box
            key={c.name}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              pt: "4px",
            }}
          >
            <Typography
              sx={{
                width: 30,
                textAlign: "right",
                mr: "5px",
                fontFamily: "Sofia Pro",
              }}
            >
              {i + 1 + 
              ((otherCerts.currentCoreCert&&otherCerts.currentCoreCert.category == "entre")?1:0) +
                  userData.certifications.entre.length 
              + "."}
            </Typography>
            <Typography
              flexGrow={1}
              sx={{
                fontFamily: "Sofia Pro",
              }}
            >
              {c.name}
            </Typography>
            <Typography
              sx={{
                mr: "10px",
                fontFamily: "Sofia Pro",
              }}
            >
              -
            </Typography>
          </Box>
        )
      })}
      <Box
        backgroundColor="#212123"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: "6px",
          pb: "3px",
        }}
      >
        <Typography
          textAlign="center"
          fontSize="20px"
          sx={{
            fontFamily: "Sofia Pro",
            p: 0,
            m: 0,
            mb: "5px",
            height: "23px",
            width: "85px",
            borderBottom: "2px solid white",
          }}
        >
          S.T.E.A.M
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mt: "10px",
          mx: "10px",
          height: "18px",
          borderBottom: "1px solid white",
        }}
      >
        <Typography
          flexGrow={1}
          sx={{
            mr: "5px",
            fontFamily: "Sofia Pro",
          }}
        >
          JOURNEY NAME
        </Typography>
        <Typography
          sx={{
            mr: "5px",
            fontFamily: "Sofia Pro",
          }}
        >
          STATUS
        </Typography>
      </Box>
      {userData.certifications.stem.map((c,i) => {
        return (
          <Box
            key={c.name}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              pt: "4px",
            }}
          >
            <Typography
              sx={{
                width: 30,
                textAlign: "right",
                mr: "5px",
                fontFamily: "Sofia Pro",
                color:c.status == "pass" ? "#fff" : did_not_pass_color,
              }}
            >
              {i +
                userData.certifications.entre.length +
                otherCerts.entre.length +
                entre_extra +
                "."}
            </Typography>
            <Typography
              flexGrow={1}
              sx={{
                fontFamily: "Sofia Pro",
                color:c.status == "pass" ? "#fff" : did_not_pass_color,
              }}
            >
              {c.name}
            </Typography>
            <Typography
              sx={{
                mr: "10px",
                fontFamily: "Sofia Pro",
                color:c.status == "pass" ? "#fff" : did_not_pass_color,
              }}
            >
              {c.status == "pass" ? "CERTIFIED" : "DID NOT PASS"}
            </Typography>
          </Box>
        )
      })}
      {otherCerts.currentCoreCert 
        && otherCerts.currentCoreCert.category == "science_and_tech" 
        && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            pt: "4px",
            color: userData.on_hold==false&&theme.palette.rc_yellow.main,
          }}
        >
          <Typography
            sx={{
              width: 30,
              textAlign: "right",
              mr: "5px",
              fontFamily: "Sofia Pro",
            }}
          >
            {userData.certifications.entre.length +
              otherCerts.entre.length +
              userData.certifications.stem.length +
              1 +
              "."}
          </Typography>
          <Typography
            flexGrow={1}
            sx={{
              fontFamily: "Sofia Pro",
            }}
          >
            {otherCerts.currentCoreCert.name}
          </Typography>
          <Typography
            sx={{
              mr: "10px",
              fontFamily: "Sofia Pro",
            }}
          >
            {(userData.on_hold==true||userData.account_status!='active')?"IN PROGRESS":"-"}
          </Typography>
        </Box>
      )}
      {otherCerts.stem.map((c,i) => {
        if(otherCerts.currentCoreCert.id == c.id){return null}
        return (
          <Box
            key={c.name}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              pt: "4px",
            }}
          >
            <Typography
              sx={{
                width: 30,
                textAlign: "right",
                mr: "5px",
                fontFamily: "Sofia Pro",
              }}
            >
              {i +
                userData.certifications.entre.length +
                userData.certifications.stem.length +
                otherCerts.entre.length +
                  (otherCerts.currentCoreCert.category == "science_and_tech"?1:0) + 
                entre_extra +
                "."}
            </Typography>
            <Typography
              flexGrow={1}
              sx={{
                fontFamily: "Sofia Pro",
              }}
            >
              {c.name}
            </Typography>
            <Typography
              sx={{
                mr: "10px",
                fontFamily: "Sofia Pro",
              }}
            >
              -
            </Typography>
          </Box>
        )
      })}
      <Box sx={{height:50}}/>
      <Box
        backgroundColor={theme.palette.rc_yellow.main}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: "6px",
          pb: "3px",
        }}
      >
        <Typography
          textAlign="center"
          fontSize="20px"
          sx={{
            fontFamily: "Sofia Pro",
            color: "#212123",
            p: 0,
            m: 0,
            mb: "5px",
            height: "23px",
          }}
        >
          UNIVERSITY CERTIFICATIONS
        </Typography>
      </Box>
      {userData.certifications.university.map((c,i) => {
        return (
          <Box
            key={c.name}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              pt: "4px",
            }}
          >
            <Typography
              sx={{
                width: 30,
                textAlign: "right",
                mr: "5px",
                fontFamily: "Sofia Pro",
                color:c.status == "pass" ? "#fff" : did_not_pass_color,
              }}
            >
              {i + 1 + "."}
            </Typography>
            <Typography
              flexGrow={1}
              sx={{
                fontFamily: "Sofia Pro",
                color:c.status == "pass" ? "#fff" : did_not_pass_color,
              }}
            >
              {c.name}
            </Typography>
            <Typography
              sx={{
                mr: "10px",
                fontFamily: "Sofia Pro",
                color:c.status == "pass" ? "#fff" : did_not_pass_color,
              }}
            >
              {c.status == "pass" ? "CERTIFIED" : "DID NOT PASS"}
            </Typography>
          </Box>
        )
      })}
      {otherCerts.university.map((c,i) => {
        return (
          <Box
            key={c.name}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              pt: "4px",
            }}
          >
            <Typography
              sx={{
                width: 30,
                textAlign: "right",
                mr: "5px",
                color: "#666666",
                fontFamily: "Sofia Pro",
              }}
            >
              {i + 1 + "."}
            </Typography>
            <Typography
              flexGrow={1}
              sx={{
                color: "#666666",
                fontFamily: "Sofia Pro",
              }}
            >
              {c.name}
            </Typography>
            <Typography
              sx={{
                color: "#666666",
                mr: "10px",
                fontFamily: "Sofia Pro",
              }}
            >
              <LockIcon sx={{ height: "17px" }} />
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}
